// Mobile Screen
@mixin tablet {
	@media screen And (max-width: 600px) {
		@content;
	}
}

@mixin mobile {
	@media screen And (max-width: 505px) {
		@content;
	}
}

/* width */
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 4px;
	margin: 14px 3px;
	cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #cecece;
	border-radius: 4px;
	cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #6596dc;
	cursor: pointer;
}

.app {
	.home {
		overflow: hidden;
		width: 100%;

		.btnDiv {
			display: flex;
			align-items: center;
			gap: 10px;
			margin: 25px 0px;
		}
		.mui-table {
			width: 100%;
			margin: auto;
			// overflow: hidden;
			margin: 20px 10px;

			.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
				width: 80%;
				margin: auto;
			}
		}
	}

	.planing {
		overflow: hidden;
		width: 100%;
	}

	.inscription {
		.btnHeader {
			@include tablet {
				flex-direction: column-reverse;
			}
			.btnHeaderItem {
				justify-content: center;
			}
			.filter {
				display: flex;
				align-items: center;
				gap: 10px;
				@include tablet {
					justify-content: space-around;
				}
				@include mobile {
					flex-direction: column;
				}
				label {
					font-weight: 500;
					font-size: 14px;
				}
				.items {
					display: flex;
					gap: 8px;
					justify-content: center;
					align-items: center;
					@include mobile {
						flex-direction: column;
						width: 100%;
					}
				}
			}
		}
	}

	.css-9rrh7h-MuiButtonBase-root-MuiFab-root {
		width: 28px !important;
		max-height: 28px !important;
		min-height: 26px;
	}

	.dashboard-layout {
		margin-left: 200px !important;
	}
	@media screen and (max-width: 992px) {
		.dashboard-layout {
			margin-left: 0 !important;
		}
	}
}

.ant-picker .ant-picker-input {
	padding: 5px;
}

.ant-picker-dropdown {
	z-index: 9999;
}

.ant-layout .ant-layout-sider-children {
	background-color: #fff;
	height: 100vh;
	display: flex;
	flex-direction: column;
	gap: 30px;

	img {
		align-self: center;
		margin-top: 10px;
		width: 100px;
		height: 100px;
		object-fit: cover;
	}
}

.ant-layout-footer.css-dev-only-do-not-override-1wazalj {
	padding: 15px;
}

.navbar.navbar-expand.navbar-light.bg-primary {
	background-color: #0177b6 !important;
}

.ant-select-single {
	@include mobile {
		width: 70% !important;
	}
}

.ant-message.ant-message-top.css-dev-only-do-not-override-1wazalj {
	z-index: 99999;
}

.ant-select-selection-overflow {
	padding: 8px !important;
}

.ant-card-body {
	padding: 0px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.ant-card-head-title {
	text-align: center !important;
	color: grey !important;
	font-size: 17px !important;
	font-weight: bold !important;
}

button.ant-btn.css-dev-only-do-not-override-1wazalj.ant-btn-primary.ant-input-search-button {
	display: flex !important;
	align-items: center !important;
}

.ant-select-selection-overflow {
	padding: 2px !important;
}

@media screen and (max-width: 992px) {
	.step1Head {
		flex-direction: column;

		.autoCompletFilter {
			width: 260px !important;
		}

		.wrapperFiltersSelect {
			flex-direction: column !important;

			.filtersSelect {
				width: 260px !important;
			}
		}
	}

	.tagItems {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.sendEmail {
		flex-direction: column;
		align-items: center;
		gap: 30px !important;
		padding: 10px;

		.sendEmailForm {
			width: 100% !important;

			.sendEmailBtn {
				width: 250px !important;
				height: 45px !important;
				justify-content: "center" !important;
				align-items: "center" !important;
				font-size: 14px !important;
			}
		}
	}
}
